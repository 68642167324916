import React from "react"
import { useStaticQuery, graphql } from "gatsby"
export default function Oppettider() {
  const data = useStaticQuery(graphql`
    query {
      wpPage(databaseId: { eq: 17 }) {
        informationStartsida {
          valkommen
          oppettider {
            dag
            tid
          }
        }
        databaseId
      }
    }
  `)
  const { oppettider } = data.wpPage.informationStartsida
  return (
    <div className="space-y-4">
      {oppettider.map((post, i) => {
        return <Oppettid key={i} post={post} />
      })}
    </div>
  )
}

function Oppettid({ post }) {
  const { dag, tid } = post
  return (
    <div className="flex flex-col">
      <p className="font-semibold">{dag}</p>
      <p>{tid}</p>
    </div>
  )
}
