import { StaticImage } from "gatsby-plugin-image"
import React from "react"

export default function Greenhouse() {
  return (
    <div className=" md:h-screen bg-secondary">
      <div className="h-full w-full overflow-hidden p-4 md:p-6">
        <StaticImage
          src="../images/1dorr.jpg"
          layout="constrained"
          className="h-full w-full  object-cover"
          formats={["jpg"]}
        />
      </div>
    </div>
  )
}
