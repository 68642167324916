import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Welcome from "../components/welcome"
import HittaHit from "../components/hitta-hit"
import Greenhouse from "../components/greenhouse"

export default function IndexPage({ data }) {
  const { valkommen } = data.wpPage.informationStartsida
  return (
    <Layout isHome>
      <SEO title="Limhamns Familjetandläkare" isHome />
      <div className="bg-primary/30">
        <Welcome data={valkommen} />
        <HittaHit />
        <Greenhouse />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    wpPage(databaseId: { eq: 17 }) {
      informationStartsida {
        valkommen
        oppettider {
          dag
          tid
        }
      }
      databaseId
    }
  }
`
