import React from "react"
import Fade from "react-reveal/Fade"
import { StaticImage } from "gatsby-plugin-image"
import Container from "./container"

export default function Welcome({ data }) {
  console.log(data)
  return (
    <Container>
      <div
        id="om-oss"
        className="flex flex-col xl:flex-row gap-8 lg:gap-16 md:gap-8  items-center py-4 md:py-24 overflow-hidden"
      >
        <Fade bottom distance="100px">
          <div className="border-[1rem] border-white flex-1 shadow-sm  order-last xl:order-first">
            {" "}
            <StaticImage
              src="../images/group.jpg"
              layout="constrained"
              className="h-full  object-cover pb-6"
              formats={["jpg"]}
            />
          </div>
        </Fade>
        <Fade right distance="100px">
          <div className="flex-1">
            <div className="text-xl md:text-2xl text-primary space-y-4">
              <h2 className="text-2xl md:text-4xl">
                Välkommen till
                <br /> Limhamns familjetandläkare
              </h2>
              <div
                dangerouslySetInnerHTML={{ __html: data }}
                className="wp-content space-y-4"
              />
            </div>
          </div>
        </Fade>
      </div>
    </Container>
  )
}
