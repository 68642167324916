import React from "react"
import Container from "./container"
import Link from "./link"
import img from "../images/karta.svg"
import Oppettider from "./oppettider"
import Kontakt from "./kontakt"
export default function HittaHit() {
  return (
    <div className="bg-secondary">
      <div
        id="kontakt"
        className="py-8 md:py-24 flex flex-col w-full lg:flex-row items-center gap-8 md:gap-16 px-4 md:px-8 text-xl overflow-hidden"
      >
        <div className="flex-1 w-full ">
          <img
            src={img}
            alt="Karta"
            className="w-full p-4 scale-125 md:scale-100 pt-8 pb-8"
          />
          <p className="text-center text-sm relative z-20">
            <Link
              to="https://www.google.com/maps/place/Limhamns+Family+Dentist/@55.584585,12.9434299,626m/data=!3m2!1e3!4b1!4m5!3m4!1s0x4653a6eae89b3d3d:0x8cdf6846e8001a37!8m2!3d55.584585!4d12.9434299"
              className="underline underline-offset-1"
            >
              <span className="inline md:hidden">Öppna</span>
              <span className="hidden md:inline">Visa</span> i Google Maps
            </Link>
          </p>
        </div>
        <div className="md:flex-none w-full lg:w-1/3 justify-self-end space-y-8">
          <div className="space-y-4">
            <h3>Kontakt</h3>
            <Kontakt />
          </div>
          <div className="space-y-4">
            <h3>Öppettider</h3>
            <Oppettider />
          </div>
        </div>
      </div>
    </div>
  )
}
